import React, { useEffect, useState } from "react"
import { getUserCurrency } from "../../../services/globalHelpers"
import AxiosInstance from "../../../services/axiosInstance"
import { fetchSettingByType } from "../../../services/settingsHelper"

const DefaultRateSetting = ({}) => {
  const [defaultRate, setDefaultRate] = useState("")
  const [settingId, setSettingId] = useState(0)
  const currency = getUserCurrency()
  const attributeName = "DefaultHourlyRate"

  const saveDefaultRate = async () => {
    const data = {
      type: attributeName,
      value: defaultRate,
      privacy: "public",
    }
    const url = settingId ? `/settings/general-setting/edit/${settingId}` : "/settings/general-setting/add"

    try {
      const response = await AxiosInstance.post(url, data)
      if ((settingId && response.status === 200) || (!settingId && response.status === 201)) {
        if (!settingId) {
          setSettingId(response.data.id)
        }

        return
      }
      console.error("Unexpected response status when trying to create default hourly rate: ", response.status)
    } catch (error) {
      console.log(error)
      return
    }
  }

  const fetchFromServer = async () => {
    const setting = await fetchSettingByType(attributeName)

    if (setting.length > 0) {
      setDefaultRate(setting[0].value)
      setSettingId(setting[0].id)
    }
  }

  useEffect(() => {
    fetchFromServer()
  }, [])

  return (
    <>
      <div className="row g-0 block-content align-items-center border-bottom">
        <div className="col-lg-12 p-3">
          <div className="row row-cols-sm-auto g-2 align-items-center">
            <div className="col-12 fw-bold">Default Hourly Rate</div>
            <div className="col-12">
              <div className="input-group">
                <span className="input-group-text">{currency.symbol}</span>
                <input
                  type="text"
                  className="form-control"
                  value={defaultRate}
                  onChange={e => setDefaultRate(e.target.value)}
                  onBlur={saveDefaultRate}
                />
                <span className="input-group-text">/hour</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DefaultRateSetting
